import React from 'react'
import { graphql } from 'gatsby'
import { v4 } from 'uuid'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Box, Button } from '@chakra-ui/react'

import Layout from '../components/layout'

import { Article, P, Image, Section, RowContent, ColImage, ColInfo, Gallery, ColVideo } from '../ui'

function GalleryPage({ data }) {
  const { t } = useTranslation()

  return (
    <Layout title={t('gallery:title')}>
      <Article>
        <Section
          title={t('gallery:title')}
          titleAlign="center"
          subtitle={t('gallery:desc')}
          spacing={10}
        >
          <RowContent>
            <ColVideo url="https://www.youtube.com/watch?v=ovk_nPBigSY" />
            <ColInfo title={t('gallery:intro.title')} backgroundColor="gray.100">
              <P>{t('gallery:intro.desc1')}</P>
              <P>{t('gallery:intro.desc2')}</P>
            </ColInfo>
          </RowContent>
        </Section>

        <Section title={t('gallery:galleries.experience.title')} titleAlign="center">
          <Gallery
            images={data.gallery.edges.map((image) => (
              <Image key={v4()} fixed={image.node.childImageSharp.fixed} layout="background" />
            ))}
            buttonText={t('common:viewPhotos')}
          />
        </Section>

        <Section
          title={t('gallery:galleries.areas.title')}
          subtitle={t('gallery:galleries.areas.desc')}
          titleAlign="center"
        >
          <Gallery
            images={data.areas.edges.map((image) => (
              <Image key={v4()} fixed={image.node.childImageSharp.fixed} layout="background" />
            ))}
            buttonText={t('common:viewPhotos')}
          />
        </Section>

        <Section
          title={t('gallery:galleries.restaurant.title')}
          subtitle={t('gallery:galleries.areas.desc')}
          titleAlign="center"
        >
          <Gallery
            images={data.restaurant.edges.map((image) => (
              <Image key={v4()} fixed={image.node.childImageSharp.fixed} layout="background" />
            ))}
            buttonText={t('common:viewPhotos')}
          />
        </Section>

        <Section title={t('gallery:galleries.rooms.title')} titleAlign="center">
          <Gallery
            images={data.rooms.edges.map((image) => (
              <Image key={v4()} fixed={image.node.childImageSharp.fixed} layout="background" />
            ))}
            buttonText={t('common:viewPhotos')}
          />
        </Section>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  query {
    featured1: file(relativePath: { eq: "index/gallery/restaurante13.jpg" }) {
      ...fixedImage
    }

    gallery: allFile(
      filter: { relativeDirectory: { eq: "index/gallery" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    areas: allFile(filter: { relativeDirectory: { eq: "gallery/areas" } }, sort: { fields: name }) {
      ...multipleFixedImage
    }

    restaurant: allFile(
      filter: { relativeDirectory: { eq: "gallery/restaurant" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    rooms: allFile(filter: { relativeDirectory: { eq: "gallery/rooms" } }, sort: { fields: name }) {
      ...multipleFixedImage
    }
  }
`

export default GalleryPage
